import React from 'react'
import './style.css'

export default function Whatsapp() {
  return (
    <div>
        <a href="https://wa.me/+918075315549" class="btn-whatsapp-pulse btn-whatsapp-pulse-border">
            <i class="fab fa-whatsapp"></i>
        </a>  
    </div>
  )
}

